<template>
  <div class="main-z2">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
      </el-header>
      <div class="main">
        <div class="w">
          <div class="tit"><img src="../../assets/images/Z/Z2/tit-en.jpg" /></div>
          <div class="tc sign-prov" v-show="page == 1">
            <div class="check-box">
              <div class="check-d" @click="changecheck(1)">
                <div :class="clickcur == 1 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">Verify email</p>
              </div>
              <div class="check-d" @click="changecheck(2)">
                <div :class="clickcur == 2 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">Verify mobile phone</p>
              </div>
            </div>
            <div class="input-form" v-show="clickcur == 1">
              <el-form :rules="z2formrule1" :model="z2form1" ref="z2form1" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="Please enter an Email Address" prop="email">
                  <el-input v-model="z2form1.email"></el-input>
                </el-form-item>
                <el-form-item label="Enter verification code" prop="code"><el-input v-model="z2form1.code"></el-input>
                <div class="sub-code" @click="getemailcode()" :disabled="getcode1.disablebtn" :class="getcode1.disablebtn ? 'disablebtn' : ''">{{ getcode1.emailgetcode }}</div>
                </el-form-item>
                <el-form-item :rules="[{ required: true }]" label="Authentication">
                  <drag-verify
                    ref="verify"
                    :width="isphone"
                    :height="44"
                    handlerIcon="el-icon-d-arrow-right"
                    successIcon="el-icon-check"
                    text="Drag the slider to the right"
                    success-text="Successful"
                    background="#ddd"
                    progress-bar-bg="#2ACBF8"
                    text-size="0.3rem"
                    :circle="false"
                    @passcallback="passcall"
                  ></drag-verify>
                </el-form-item>
              </el-form>
            </div>
            <div class="input-form" v-show="clickcur == 2">
              <el-form :rules="z2formrule2" :model="z2form2" ref="z2form2" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="Please enter a phone number" prop="phone" class="poneinput">
                  <el-input v-model="z2form2.areacode" type="number" class="areacode">
                    <template slot="prepend">
                      +
                    </template>
                  </el-input>
                  <el-input v-model="z2form2.phone" type="number" class="phone"></el-input>
                </el-form-item>
                <el-form-item label="Enter verification code" prop="code"><el-input v-model="z2form2.code"></el-input>
                <div class="sub-code" @click="getphonecode()" :disabled="getcode2.disablebtn" :class="getcode2.disablebtn ? 'disablebtn' : ''">{{ getcode2.phonegetcode }}</div></el-form-item>
                <el-form-item :rules="[{ required: true }]" label="Authentication">
                  <drag-verify
                    ref="verify2"
                    :width="isphone"
                    :height="44"
                    handlerIcon="el-icon-d-arrow-right"
                    successIcon="el-icon-check"
                    text="Drag the slider to the right"
                    success-text="Successful"
                    background="#ddd"
                    progress-bar-bg="#2ACBF8"
                    text-size="0.3rem"
                    :circle="false"
                    @passcallback="passcall2"
                  ></drag-verify>
                  <!-- <slide-verify :l="42"
                      :r="10"
                      :w="425"
                      :h="212.5"
                      @success="passcall2"
                      @fail="onFail"
                      @refresh="onRefresh"
                      :slider-text="'Successful'"
                      ></slide-verify> -->
                </el-form-item>
              </el-form>
            </div>
            <div class="btns">
              <a class="btn1" @click="$router.go(-1)">Cancel</a>
              <a class="btn2" @click="checkform()">Next</a>
            </div>
          </div>

          <div class="tc sign-changenewpass" v-show="page == 2">
            <div class="input-form">
              <el-form :rules="z2formrule3" :model="z2form3" ref="z2form3" status-icon label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="Please enter a new password" prop="newpass"><el-input v-model="z2form3.newpass" type="password"></el-input></el-form-item>
                <el-form-item label="Please enter the new password again" prop="checkpass"><el-input v-model="z2form3.checkpass" type="password"></el-input></el-form-item>
              </el-form>
            </div>
            <div class="btns">
              <a class="btn1" @click="page = 1">Cancel</a>
              <a class="btn2" @click="changepasscheck()">Completed</a>
            </div>
          </div>

          <div class="tc sign-success" v-show="page == 3">
            <i class="suc-icon el-icon-circle-check"></i>
            <p class="suc-der">Congratulations! Your password has been reset.</p>
            <div class="btns"><a class="btn2" @click="goLogin">Log in now</a></div>
          </div>
        </div>
      </div>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import dragVerify from 'vue-drag-verify'
import qs from 'qs'
import { findpsw } from '../../api'
import { testphone2, testemail2 } from '../../api-en'
export default {
  data () {
    var checkemail = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (reg.test(this.z2form1.email)) {
        callback()
      } else {
        callback(new Error('Incorrect mailbox format'))
      }
    }
    var checknewpass = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z2form3.newpass.match(regx) == null) {
        callback(new Error('Your password needs to be a combination of numbers and letters'))
      } else {
        callback()
      }
    }
    var checknewpass2 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
      if (this.z2form3.newpass != this.z2form3.checkpass) {
        callback(new Error('Please enter the new password again '))
      } else {
        if (this.z2form3.checkpass.match(regx) == null) {
          callback(new Error('Your password needs to be a combination of numbers and letters'))
        } else {
          callback()
        }
      }
    }
    return {
      checked: true,
      clickcur: 1,
      page: 1,
      isphone: 0,
      z2form1: {
        email: '',
        code: '',
        pass: '',
        checkpass: '',
        getcode: '',
        checkdrag: false
      },
      z2form2: {
        areacode: '86',
        phone: '',
        code: '',
        pass: '',
        checkpass: '',
        getcode: '',
        checkdrag2: false
      },
      z2form3: {
        newpass: '',
        checkpass: ''
      },
      z2formrule1: {
        email: [{ required: true, validator: checkemail }],
        code: [{ required: true, message: 'Please enter the security code', trigger: 'blur' }]
      },
      z2formrule2: {
        phone: [{ required: true, message: 'Please enter phone number', trigger: 'blur' }],
        code: [{ required: true, message: 'Please enter the security code', trigger: 'blur' }]
      },
      z2formrule3: {
        newpass: [{ required: true, validator: checknewpass, trigger: 'blur' }, { min: 6, max: 24, message: 'Your password must be a combination of 6 to 24 numbers and letters' }],
        checkpass: [{ required: true, validator: checknewpass2, trigger: 'blur' }, { min: 6, max: 24, message: 'Your password must be a combination of 6 to 24 numbers and letters' }]
      },
      getcode1: {
        emailgetcode: 'Get verification code',
        disablebtn: false,
        timer: null,
        count: ''
      },
      getcode2: {
        phonegetcode: 'Get verification code',
        disablebtn: false,
        timer: null,
        count: ''
      },
      startX:'',
      startY:''
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        window.addEventListener("touchstart", this.touchstart);
        window.addEventListener("touchmove", this.touchmove, {
          passive: false
        });
        this.isphone = parseInt($('.main-z2 .input-form').width())
      }, 200)
      window.addEventListener('resize', () => {
        this.isphone = parseInt($('.main-z2 .input-form').width())
      })
    })
  },
  beforeDestroy() {
    window.removeEventListener('touchstart',this.touchstart)
    window.removeEventListener('touchmove',this.touchmove)
  },
  methods: {
    goLogin () {
      this.$router.push('/LoginEN')
    },
    changecheck (i) {
      this.clickcur = i
    },
    checkform () {
      let formid, form, isdrag
      if (this.clickcur == 1) {
        formid = 'z2form1'
        form = this.z2form1
        isdrag = this.z2form1.checkdrag
      } else {
        formid = 'z2form2'
        form = this.z2form2
        isdrag = this.z2form2.checkdrag2
      }
      this.$refs[formid].validate(valid => {
        if (valid) {
          if (this.getmd5(form.code) != form.getcode) {
            this.resetVerify()
            this.$message({
              showClose: true,
              message: 'Wrong security code! ',
              type: 'error',
              center: true
            })
            return false
          } else if (!isdrag) {
            this.$message({
              showClose: true,
              message: 'Please finish security verification',
              type: 'error',
              center: true
            })
            return false
          } else {
            this.page = 2
          }
        }
      })
    },
    changepasscheck () {
      this.$refs['z2form3'].validate(valid => {
        if (valid) {
          // console.log('success')
          let number
          if (this.clickcur == 1) {
            number = this.z2form1.email
          } else {
            number = this.z2form2.phone
          }
          // console.log(number)
          let list = {
            USERNAME: number,
            PASSWORD: this.getmd5(this.z2form3.newpass)
          }
          findpsw(qs.stringify(list)).then(res => {
            // console.log(res)
            if (res.data == 'ok') {
              this.$message({
                showClose: true,
                message: 'Password changed successfully ',
                type: 'success',
                center: true
              })
              this.page = 3
            } else {
              this.$message({
                showClose: true,
                message: 'Password change failed. Please try again',
                type: 'error',
                center: true
              })
            }
          })
        }
      })
    },
    passcall () {
      this.z2form1.checkdrag = true
    },
    passcall2 (e) {
      this.z2form2.checkdrag2 = true
    },
    getemailcode () {
      this.$refs.z2form1.validateField('email', valid => {
        if (this.z2form1.email == '') {
          this.$message({
            showClose: true,
            message: 'Please enter the email',
            type: 'error',
            center: true
          })
          return false
        } else if (valid) {
          this.$message({
            showClose: true,
            message: 'Incorrect mailbox format',
            type: 'error',
            center: true
          })
          return false
        } else {
          let em = this.z2form1.email
          testemail2(em).then(res => {
            // console.log(res)
            if (typeof res.data == 'string') {
              this.getcode1.emailgetcode = 'Get verification code'
              const TIME_COUNT = 60
              if (!this.getcode1.timer) {
                this.getcode1.count = TIME_COUNT
                this.getcode1.emailgetcode = 'Get verification code'
                this.getcode1.timer = setInterval(() => {
                  if (this.getcode1.count > 0 && this.getcode1.count <= TIME_COUNT) {
                    this.getcode1.count--
                    this.getcode1.emailgetcode = `${this.getcode1.count}s`
                    this.getcode1.disablebtn = true
                  } else {
                    this.getcode1.emailgetcode = 'Get verification code'
                    this.getcode1.disablebtn = false
                    clearInterval(this.getcode1.timer)
                    this.getcode1.timer = null
                  }
                }, 1000)
              }
              this.$message({
                showClose: true,
                message: 'Mail sent successfully',
                type: 'success',
                center: true
              })
              this.z2form1.getcode = res.data
            } else {
              this.$message({
                showClose: true,
                message: 'Mail failed to send',
                type: 'error',
                center: true
              })
            }
          })
        }
      })
    },
    getphonecode () {
      this.$refs.z2form2.validateField('phone', valid => {
        if (this.z2form2.phone == '') {
          this.$message({
            showClose: true,
            message: 'Please enter phone number',
            type: 'error',
            center: true
          })
          return false
        } else if (valid) {
          this.$message({
            showClose: true,
            message: 'Phone format error',
            type: 'error',
            center: true
          })
          return false
        } else {
          let num = this.z2form2.areacode == '86' ? this.z2form2.phone : this.z1form2.areacode + this.z2form2.phone
          testphone2(num).then(res => {
            // console.log(typeof res.data)
            if (!res.data) {
              this.$message({
                showClose: true,
                message: 'Incorrect mobile phone format or limit of  times of sending security code  reached',
                type: 'error',
                center: true
              })
            } else if (typeof res.data == 'string') {
              this.getcode2.phonegetcode = 'Get verification code'
              const TIME_COUNT = 60
              if (!this.getcode2.timer) {
                this.getcode2.count = TIME_COUNT
                this.getcode2.phonegetcode = 'Get verification code'
                this.getcode2.timer = setInterval(() => {
                  if (this.getcode2.count > 0 && this.getcode2.count <= TIME_COUNT) {
                    this.getcode2.count--
                    this.getcode2.phonegetcode = `${this.getcode2.count}s`
                    this.getcode2.disablebtn = true
                  } else {
                    this.getcode2.phonegetcode = 'Get verification code'
                    this.getcode2.disablebtn = false
                    clearInterval(this.getcode2.timer)
                    this.getcode2.timer = null
                  }
                }, 1000)
              }
              this.$message({
                showClose: true,
                message: 'SMS security code has been sent',
                type: 'success',
                center: true
              })
              this.z2form2.getcode = res.data
              // console.log(this.z2form2.getcode)
            }
          })
        }
      })
    },
    getmd5 (code) {
      return this.$md5(code)
    },
    resetVerify () {
      // 恢复滑动到原点
      $('.main-z2 .dv_handler i').attr('class', 'el-icon-d-arrow-right')
      $('.main-z2 .dv_progress_bar').css('background', 'rgb(42, 203, 248)')
      $('.main-z2 .dv_handler').removeClass('dv_handler_ok_bg')
      this.$refs.verify.isMoving = false
      this.$refs.verify.x = 0
      this.$refs.verify.isPassing = false
      this.$refs.verify.$refs.handler.style.left = '0'
      this.$refs.verify.$refs.progressBar.style.width = '0'
      this.$refs.verify2.isMoving = false
      this.$refs.verify2.x = 0
      this.$refs.verify2.isPassing = false
      this.$refs.verify2.$refs.handler.style.left = '0'
      this.$refs.verify2.$refs.progressBar.style.width = '0'
      this.z2form1.checkdrag = false
      this.z2form2.checkdrag2 = false
    },
    touchstart(e){
      this.startX = e.targetTouches[0].pageX;
      this.startY = e.targetTouches[0].pageY;
    },
    touchmove(e){
      var moveX = e.targetTouches[0].pageX;
      var moveY = e.targetTouches[0].pageY;
      if (Math.abs(moveX - this.startX) > Math.abs(moveY - this.startY)) {
        e.preventDefault();
      }
    }
  },
  components: { dragVerify }
}
</script>

<style scoped lang="stylus">
.main-z2
  .main
    padding 2rem 0 2rem
    background #fff
  .tit
    text-align left
    padding-bottom 0.6rem
  .tc
    text-align left
    max-width 9.0888rem
    min-height 11.2rem
    margin 0 auto 0
    .input-form
      padding 0 0.6rem 0.8rem 0.6rem
    .btns
      text-align center
      margin-bottom 0.4rem
      a
        width 42%
        height 0.8333rem
        display inline-block
        line-height 0.8333rem
        font-size 0.2962rem
      .btn1
        color #515555
        background #fff
        border 0.0185rem solid #d3d3d3
        margin 0 0.1rem 0 0
      .btn2
        color #fff
        background #a5cd39
        border 0.0185rem solid #a5cd39
        margin 0 0 0 0.1rem
    &.sign-prov
      .check-box
        padding 0.8rem 0.6rem 0 0.6rem
        .check-d
          cursor pointer
          display inline-block
          padding 0 1rem 0 0
          .check-i
            display inline-block
            vertical-align middle
            width 0.2592rem
            height 0.2592rem
            border-radius 50%
            border 0.0185rem solid #8e8e8e
            position relative
            margin 0 0.2rem 0 0
            transition all 0.2s
            &.select
              border 0.0185rem solid #00b4d7
              transition all 0.2s
              &::before
                content ''
                width 0.1481rem
                height 0.1481rem
                border-radius 50%
                position absolute
                top 50%
                left 50%
                margin -(@height / 2) 0 0 0 - (@width / 2)
                background #00b4d7
          p
            color #8d8d8d
            font-size 0.2962rem
            display inline-block
            vertical-align middle
      .input-form
        padding 0.6rem 0.6rem 0.2rem 0.6rem
        .cnt-input
          .input
            width 73%
          .sub-code
            width 3.3333rem
            height 0.7407rem
            background #a5cd39
            border none
            outline none
            text-align center
            line-height 0.7407rem
            color #fff
            font-size 0.2962rem
            display inline-block
            vertical-align bottom
        .drag_verify
          display inline-block
      .el-checkbox__input.is-checked+.el-checkbox__label
        color #8d8d8d
    &.sign-success
      text-align center
      padding-top 1.8rem
      .suc-icon
        color #a5cd39
        font-size 0.9407rem
      .suc-der
        color #8d8d8d
        font-size 0.2962rem
        padding 0.2rem 0 0.6rem
</style>
<style lang="stylus">
.main-z2{
  .main{
    .w{
      .tc{
        .z1-form1{
          .el-form-item__label{
            color #8d8d8d
            font-size 0.2962rem
          }
          .el-form-item{
            margin-bottom .3rem
            &.poneinput{
              .el-input-group__prepend{
                padding 0 .2rem
                border-radius 0
              }
              .areacode{
                width 28%
              }
              .phone{
                width 72%
                padding 0 0 0 .2rem
              }
            }
            .el-form-item__content{
              margin 0 !important
              .el-input__inner{
                border-radius 0
                border 0.0185rem solid #d1d1d1
                background #f7f9fa
              }
            }
            &:nth-child(2){
              position relative
              .sub-code{
                width 3.3333rem
                height 40px
                background #a5cd39
                border none
                outline none
                text-align center
                line-height 40px
                color #fff
                font-size 0.2962rem
                display inline-block
                vertical-align bottom
                position absolute
                right 0
                cursor pointer
                transition all .2s
                &.disablebtn{
                  cursor no-drop
                  background #b3e19d
                }
              }
            }
          }
        }
        .drag_verify .dv_handler{
          width 44px !important
          height 44px !important
        }
      }
    }
  }
}
@media screen and (max-width: 990px){
.main-z2{
    .main{
      .w{
        .tit{
          padding 0 .37rem
          img{
            width 40%
          }
        }
        .tc{
          .check-box{
            .check-d{
              p{
                font-size 0.3333rem
              }
            }
          }
          .z1-form1{
            .el-form-item__label{
              font-size 0.3333rem
            }
          }
          .btns{
            a{
              height 1.0333rem
              line-height 1.0333rem
              font-size 0.3333rem
            }
          }
          .suc-der{
            font-size 0.3333rem
          }
        }
      }
    }
  }
}
</style>
